html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#main_content {
  height: calc(100% - 56px);
}
/*# sourceMappingURL=index.d0110fe8.css.map */
